import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  headerMode: 'full',
  menuMode: 'tenant',
  hideDineIn: false,
  headerData: {
    userFullName: '',
    instituteId: '',
    instituteName: '',
    balance: '0',
    points: '0',
    isAuthenticated: false,
    isTrollyShowed: false,
    totalCart: 0,
  },
})

// getters
const getters = {
  headerMode(state) {
    return state.headerMode
  },
  headerData(state) {
    return state.headerData
  },
  menuMode(state) {
    return state.menuMode
  },
  hideDineIn(state) {
    return state.hideDineIn
  },
}

// actions
const actions = {
  checkHealth({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}health/`)
        .then((response) => {
          resolve({
            status: response.status,
            data: response.data,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  pageConfig({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}page-config/`)
        .then((response) => {
          resolve({
            status: response.status,
            data: response.data.data,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setMenuMode({ commit }, payload) {
    commit('setMenuMode', payload)
  },
  setHideDineIn({ commit }, payload) {
    commit('setHideDineIn', payload)
  },
}

// mutations
const mutations = {
  setHeaderMode(state, val) {
    state.headerMode = val
  },
  setHeaderData(state, val) {
    state.headerData = val
  },
  setMenuMode(state, val) {
    state.menuMode = val
  },
  setHideDineIn(state, val) {
    state.hideDineIn = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
